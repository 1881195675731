<template>
  <div class="mySelect">
    <label>
      <input type="text" @click="onShow" :placeholder="placeholderTxt" v-model="txtInput" readonly>
    </label>
    <br>
    <label>
      <select ref="txtSelect" :size="selectSize" hidden="hidden" @change="onChanged($event)" @scroll="onScroll($event)" @blur="onHide">
        <template v-for="item in items">
          <option :value="item.id" :disabled="item.id.length <= 0">{{ item.name }}</option>
        </template>
      </select>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    placeholderTxt: {
      type: String,
      default: "请选择"
    },
    selectSize: {
      type: Number,
      default: 10
    },
    optionData: {
      type: Array,
      default: () => []
    },
    loadMore: {
      type: Boolean,
      default: false
    },
    action: {
      type: Function,
      default: () => {}
    },
    params: {
      type: Object,
      default: () => ({
        pageNum: 1,
        pageSize: 11
      })
    },
    isDefaultLoad: {
      type: Boolean,
      default: true
    },
    change: {
      type: Function,
      default: () => {}
    },
    obj: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      txtInput: this.optionData.length > 0 ? this.optionData[0].name : "",
      items: this.optionData,
      pageTotal: 0,
      currentPageNum: 0,
      isLoading: false
    }
  },
  created() {
    if(this.isDefaultLoad){
      this.fetchData(1);
    }
  },
  watch: {
    params: {
      handler() {
        if(this.isLoading){
          return;
        }

        this.items = [];
        this.txtInput = "";
        this.$refs.txtSelect.selectedIndex = -1;
        this.fetchData(1);
      },
      deep: true
    }
  },
  methods: {
    onShow() {
      this.$refs.txtSelect.style.display = "block";
      this.$refs.txtSelect.focus();
    },
    onChanged(event) {
      let item = this.items[event.target.selectedIndex];
      this.txtInput = item.name;
      this.onHide();
      this.change(item, this.obj);
    },
    onScroll(event) {
      if(this.isLoading){
        return;
      }

      if(this.loadMore && (event.target.scrollHeight - event.target.scrollTop) <= event.target.clientHeight){
        if(this.currentPageNum >= this.pageTotal){
          return;
        }
        this.fetchData(this.currentPageNum + 1);
      }
    },
    onHide() {
      this.$refs.txtSelect.style.display = "none";
    },
    fetchData(pageNum) {
      if(this.isLoading){
        return;
      }

      this.isLoading = true;
      this.params.pageNum = pageNum;
      this.items.push({ id: "", name: "加载中..." });
      this.action(this.params)
        .then(data => {
          this.items.pop();
          this.currentPageNum = data.pageNum;
          data.list.forEach(e => {
            this.items.push(e);
          })

          if(data.pageNum === 1){
            this.pageTotal = data.pages;
          }

          if(this.currentPageNum >= this.pageTotal){
            this.items.push({ id: "", name: "没有更多数据" });
          }
          this.isLoading = false;
        })
        .catch(error => {
          this.items.pop();
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询数据失败");
          }
          this.isLoading = false;
        })
    }
  }
}
</script>

<style lang="less" scoped>
.mySelect {
  position: relative;
  input {
    width: calc(100% - 12px);;
    height: 100%;
    padding: 0 5px;
    border: 1px solid #dcdcdc;
  }
  select {
    width: 100%;
    left: 0;
    padding: 0 3px;
    border: 1px solid #dcdcdc;
    position: absolute;
    z-index: 99;
    option:disabled {
      text-align: center;
    }
  }
}
</style>