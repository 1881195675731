<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <div class="item-name-div">标题：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:60" v-slot="{ errors }">
              <label><input class="video-name-div" name="title" type="text" v-model="pinInfo.title" maxlength="60" placeholder="标题"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">品牌：</div>
          <div class="item-content-div">
            <my-select class="lecturer-select" :load-more="true" :action="teachBrandNameAction" :change="onBrandChanged" placeholder-txt="请选择品牌"/>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">内容：</div>
          <div class="item-content-div">
            <div class="video-img-div" v-show="pinFile != null">
              <img class="del-img" src="../../assets/del.png" alt="" @click="onDelImg">
              <img src="../../assets/file.png" alt=""/>
            </div>
            <div v-show="pinFile == null">
              <label class="upload-img-label" @change="onAddFile($event)">
                <img class="cursor-el" src="../../assets/picture.png" alt="" />
                <input class="cursor-el" type="file" />
              </label>
            </div>
          </div>
        </div>
        <div class="operate-div">
          <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
          <div class="text-c">
            <input type="button" value="取消" @click="onCancel">
            <input class="save-input" type="button" value="保存" @click="onSave" :disabled="this.cancelToken != null">
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import MySelect from "../../components/MySelect"
import {getCancelTokenSource, getTeachBrandNameItems, postFailureCaseAdd} from "@/common/api";

export default {
  components: { MySelect },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      pinInfo: {
        title: "",
        brandId: ""
      },
      teachBrandNameAction : getTeachBrandNameItems,
      pinFile: null,
      cancelToken: null,
      tipMsg: ""
    }
  },
  methods: {
    onBrandChanged(item) {
      this.pinInfo.brandId = item.id
    },
    onAddFile(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png|pdf)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("文件仅支持jpg、jpeg、png和pdf格式");
        return;
      }

      this.pinFile = event.target.files[0];
    },
    onDelImg() {
      this.pinFile = null;
    },
    onCancel() {
      if(this.cancelToken != null){
        this.cancelToken.cancel("Operation canceled by the user.");
      }
      this.$layer.close(this.layerid);
    },
    onSave() {
      if(this.pinInfo.brandId <= 0){
        this.setTipsMsg("请选择品牌");
        return;
      }
      if(this.pinFile == null){
        this.setTipsMsg("请上传内容");
        return;
      }

      let formData = new FormData();
      formData.append("title", this.pinInfo.title);
      formData.append("brandId", this.pinInfo.brandId);
      formData.append("file", this.pinFile);

      this.$refs.form.validate().then(res => {
        if(res){
          this.tipMsg = "正在上传，请勿关闭该页面。"
          this.cancelToken = getCancelTokenSource();
          postFailureCaseAdd(formData, this.cancelToken.token, this.onUploadProgress)
              .then(() => {
                this.refreshData();
                this.$layer.close(this.layerid);
                this.$layer.msg("操作成功");
              })
              .catch(error => {
                if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                  this.$layer.msg(error.data.msg);
                } else {
                  this.$layer.msg("操作失败");
                }
              });
        }
      });
    },
    onUploadProgress(progressEvent) {
      this.tipMsg =  `正在上传，请勿关闭该页面。（${progressEvent.total > 0 ? Math.floor(progressEvent.loaded / progressEvent.total * 100) : 0}%）`;
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .item-name-div {
        width: 70px;
        text-align: right;
        margin-right: 15px;
      }
      .item-content-div {
        display: inline-flex;
        input {
          width: 108px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
        textarea {
          width: 302px;
          height: 62px;
          resize: none;
          padding: 3px;
          border: 1px solid #dcdcdc;
        }
        .lecturer-select {
          width: 230px;
          height: 33px;
        }
        .video-name-div {
          width: 218px;
        }
        .err-tips {
          margin-left: 5px;
        }
      }
      .video-img-div {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          align-items: center;
        }
        .del-img {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .operate-div {
      margin-top: 10px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
      }
    }
  }
}
</style>