<template>
  <div class="layer-page-div">
    <div>
      <label>{{inputTitle}}</label>
      <input class="value-input" type="text" v-model="value" :maxlength="valueMaxLength" :placeholder="placeholder">
    </div>
    <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
    <div class="operation-btn-div">
      <input type="button" value="取消" @click="onCancel">
      <input type="button" value="确定" @click="onConfirm">
    </div>
  </div>
</template>

<script>
export default {
  name: "InputLayer",
  props: {
    layerid: {
      type: String,
      default: ""
    },
    inputTitle: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    valueMaxLength: {
      type: Number,
      default: 1
    },
    defaultValue: {
      type: String,
      default: ""
    },
    regex: {
      type: Object,
      default: null
    },
    emptyTips: {
      type: String,
      default: ""
    },
    tips: {
      type: String,
      default: ""
    },
    confirm: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      value: this.defaultValue,
      tipMsg: ""
    }
  },
  methods: {
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onConfirm() {
      if(this.value.length < 1){
        this.setTipsMsg(this.emptyTips);
        return;
      }
      if(this.regex != null && !this.regex.test(this.value)){
        this.setTipsMsg(this.tips);
        return;
      }
      this.confirm(this.layerid, this.value)
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
  .value-input {
    width: 260px;
    height: 30px;
    border: 1px solid #dcdcdc;
    margin-left: 10px;
    margin-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .tips-div {
    width: 100%;
    height: 25px;
  }
  .operation-btn-div {
    margin-top: 10px;
    input {
      width: 120px;
      height: 35px;
      color: white;
      font-size: 16px;
      border: none;
      background: #4676c8;
      margin-top: 10px;
    }
    input:first-child {
      margin-right: 10px;
    }
  }
}
</style>